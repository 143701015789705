import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice, ProjectHeader, ProjectDetails, ProjectNext } from 'components';

const Project = ({ location, data, entry }) => {
  const { state } = entry;
  const { prismicProject, prismicPage } = data;
  const {
    data: projectData,
    uid,
    first_publication_date: publishedDate,
    last_publication_date: updatedDate,
  } = prismicProject;

  const { data: homeData } = prismicPage;
  const projectGridItems = homeData?.body?.[0]?.items || [];
  const indexOfCurrentItem = projectGridItems.findIndex((item) => item?.project?.uid === uid);
  const nextItem = projectGridItems[indexOfCurrentItem + 1] || projectGridItems[0];
  const nextProjectUrl = nextItem?.project?.url;
  const nextProjectData = nextItem?.project?.document?.data;

  const {
    name,
    image,
    location: locationObj,
    type,
    status,
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = projectData;

  const seo = {
    title: metaTitle?.text || `${name?.text} - Lombardo Design Studio`,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    publishedDate,
    updatedDate,
  };

  return (
    <div className="project-body">
      <div className="project-container" id="projectContainer">
        <Layout location={location} seo={seo} fixedHeader>
          <ProjectHeader name={name?.text} image={state?.image || image} />
          <ProjectDetails location={locationObj?.text} status={status?.text} type={type} />
          {sliceData.map((slice) => (
            <Slice key={slice.id} data={slice} />
          ))}
        </Layout>
      </div>
      <ProjectNext project={{ ...nextProjectData, url: nextProjectUrl }} />
      <a id="bottom" ariaLabel="Bottom of page" />
    </div>
  );
};

export default withPrismicPreview(Project);

export const projectQuery = graphql`
  query ProjectBySlug($uid: String) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        image {
          gatsbyImageData(aspectRatio: 1.33, placeholder: BLURRED, layout: FULL_WIDTH)
        }
        name {
          text
        }
        location {
          text
        }
        status {
          text
        }
        type
        body {
          ...OneUpProjectFragment
          ...TwoUpProjectFragment
          ...TextProjectFragment
        }
      }
    }
    prismicPage(uid: { eq: "home" }) {
      data {
        body {
          ... on PrismicPageDataBodyProjectGrid {
            id
            items {
              project {
                url
                uid
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        gatsbyImageData(aspectRatio: 1.33, placeholder: BLURRED, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
